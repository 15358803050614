import {createRouter, createWebHashHistory} from "vue-router";
// import request from "umi-request";


const routes = [
    {
        //在路径是/的时候，路由到imgUpload
        path: "/",
        redirect: "/index",
        component: () => import("@/components/index/index"),
        meta: {
            title: '首页',
            requiresAuth: false, // false表示不需要登录
        },
    },
    {
        //在路径是/userCount的时候，路由到userCount
        path: "/index", //命名路由
        name: "index", //路由名称
        component: () => import("@/components/index/index"),
        meta: {
            title: '首页',
            requiresAuth: false, // false表示不需要登录
        },
    },
    {
        //在路径是/imgUpload的时候，路由到imgUpload
        path: "/introduction",
        name: "introduction",
        component:() => import("@/components/indroduction/introduction"),
        meta: {
            title: '公司简介',
            requiresAuth: false, // false表示不需要登录
        },
    },
    {
        //在路径是/imgUpload的时候，路由到imgUpload
        path: "/product",
        name: "product",
        component: () => import("@/components/product/index"),
        meta: {
            title: '产品中心',
            requiresAuth: false, // false表示不需要登录
        },
    },
    {
        //在路径是/imgUpload的时候，路由到imgUpload
        path: "/productInfo",
        name: "productInfo",
        component: () => import("@/components/product/details"),
        meta: {
            title: '产品详情',
            requiresAuth: false, // false表示不需要登录
        },
    },
    {
        //在路径是/imgUpload的时候，路由到imgUpload
        path: "/exhibition",
        name: "exhibition",
        component: () => import("@/components/exhibition/exhibition"),
        meta: {
            title: '展览回顾',
            requiresAuth: true, // true表示需要登录
        },
    },
    {
        //在路径是/imgUpload的时候，路由到imgUpload
        path: "/about",
        name: "about",
        component: () => import("@/components/about/about"),
        meta: {
            title: '详细资料',
            requiresAuth: true, // true表示需要登录
        },
    },
    {
        //在路径是/imgUpload的时候，路由到imgUpload
        path: "/message",
        name: "message",
        component: () => import("@/components/message/message"),
        meta: {
            title: '联系我们',
            requiresAuth: true, // true表示需要登录
        },
    }
]

//导出router
let router = createRouter({
    history: createWebHashHistory(), //不同的历史记录模式详情看：https://router.vuejs.org/zh/guide/essentials/history-mode.html
    routes: routes
})



export {router}
