<template>
  <div class="overflow_hide">
    <div class="nav_par body">
      <div class="logo ">
        <img src="@/assets/img/nav_logo.png">
      </div>
      <div class="nav_s_p">
        <div class="navs">
          <div class="nav">
            <div class="nav_line " :class="selectedKeys === 1?'nav_line_active':''"></div>
            <div class="nav_title">
              <router-link to="/index">
                首页
              </router-link>
            </div>
          </div>
          <div class="nav">
            <div class="nav_line" :class="selectedKeys === 2?'nav_line_active':''"></div>
            <div class="nav_title">
              <router-link to="/introduction">
                公司简介
              </router-link>
            </div>
          </div>
          <div class="nav">
            <div class="nav_line" :class="selectedKeys === 3?'nav_line_active':''"></div>
            <div class="nav_title">
              <router-link to="/product">
                产品中心
              </router-link>
            </div>
          </div>
          <div class="nav">
            <div class="nav_line" :class="selectedKeys === 4?'nav_line_active':''"></div>
            <div class="nav_title">
              <router-link to="/exhibition">
                展览回顾
              </router-link>
            </div>
          </div>
          <div class="nav">
            <div class="nav_line" :class="selectedKeys === 5?'nav_line_active':''"></div>
            <div class="nav_title">
              <router-link to="/about">
                联系我们
              </router-link>
            </div>
          </div>
        </div>
        <div class="flag shou">
          <img @click="jumpToWeb" src="@/assets/img/nav_flag.png">
        </div>
      </div>

    </div>
  </div>

  <div>
    <router-view></router-view>
  </div>
  <div class="footer ">
    <div class="body">
      <div class="footer_logo">
        <img src="@/assets/img/footer_bac.png">
      </div>
      <div class="footer_span">
      <span>
        意大利水智安阀芯公司杭州代表处：杭州市杭大路15号嘉华国际1116室
      </span>
        <span style="margin-bottom: 20px">
       电话:0571 87632309 87632306  传真:0571 87632307  E-mail:info@stsr.biz
      </span>
        <span>
      </span>
        <span>
        S.T.S.R. S.r.l. -Via Milano, 7/5 - 20084 - Lacchiarella (MI) - Italy
      </span>
        <span>
        Tel. +39 02 90030656 - +39 02 90076832 - Fax.+39 02 9007569 - E-mail. info@stsr.it
      </span>
      </div>
    </div>

  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  name: 'App',
  data() {
    return {
      selectedKeys: ref(0),
    }
  },
  components: {},
  created() {
    // bingMap.init()
  },
  methods: {
    jumpToWeb(){
      window.open("http://www.stsr.it/")
    },
  },
  watch: {
    '$route': function () {
      console.log(this.$route.path)
      switch (this.$route.path) {
        case "/":
        case "/index":
          this.selectedKeys = ref(1)
          break;
        case "/introduction":
          this.selectedKeys = ref(2)
          break;
        case "/product":
        case "/productInfo":
          this.selectedKeys = ref(3)
          break;
        case "/exhibition":
          this.selectedKeys = ref(4)
          break;
        case "/about":
          this.selectedKeys = ref(5)
          break;
      }

    },
  }
}
</script>

<style>

.shou {
  cursor: pointer;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html{
  zoom: .8;
}
html, body {
  width: 100%;
  height: auto !important;
}
html{
  min-height: 100%;
  position: relative;
}
#app{
  padding-bottom: 160px;
}
.body {
  width: 1400px;
  margin: 0 auto;

}

.overflow_hide {
  width: 100%;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  z-index: 200;
}

.nav_par {
  height: 120px;

  position: relative;
  background: url("@/assets/img/nav_bac.png") no-repeat 100%;
  background-size: cover;
  display: flex;
  justify-content: space-between;
}

.nav_par::before,
.nav_par::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc((100vw - 700px)/2);
  background-color: #C1272D; /* 左侧背景色 */
}

.nav_par::before {
  left: calc(-1 *(100vw - 700px)/2);
}

.nav_par::after {
  right: calc(-1 *(100vw - 700px)/2);
  background-color: #052665; /* 右侧背景色 */
}

.logo img {
  height: 120px;
  width: 120px;
  margin-left: 100px;
}

.navs {
  display: flex;
}

.nav {
  width: 80px;
  height: 30px;
  margin: 30px 5px 0;
}

.nav a {
  color: white;
}

.nav_line {
  background: white;
  border-radius: 10px;
  height: 2px;
}

.nav_line_active {
  background: #C1272D;
}

.nav_s_p {
  display: flex;
}

.flag {
  margin: 0 20px 0 50px;
  height: 50px;
}

.flag img {
  /*width: 40px;*/
  height: 50px;
}

.footer {
  height: 160px;
  background: #052665;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer_logo {
  position: relative;
  top: -47px;
}

.footer_logo img {
  width: 150px;
  height: 160px;
}

.footer_span span {
  display: inherit;
  color: white;
  font-size: 12px;
  font-weight: lighter;
  letter-spacing: 2px;
}

.footer .body {
  /*margin-top: 50px;*/
  display: flex;
  justify-content: space-around;
}

.footer_span {
  margin-top: 40px;
}

@font-face {
  font-family: 'FZZ';
  src: url("@/assets/font/PingFang.ttc");
}
</style>
